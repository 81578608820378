<template>
  <div class="integration-details">

    <!-- Loading -->
    <div v-if="loading && ! error" class="container loading" >
      <b-card>
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
      </b-card>
    </div>

    <!-- Page Error -->
    <div v-if="error" class="container error mt-5">
      <b-alert show dismissible variant="danger">
        <span v-html="error"></span>
      </b-alert>
      <a href="#" @click="$router.go(-1)">Go back</a>
    </div>
     
    {{ integration }}
    <!-- JOB LOADED -->
    <div v-if="! loading && integration" class="content">

      <!-- grey intro -->
      <div class="bg-site-light pt-4 mb-4">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-end py-2 text-center text-sm-left">
                <div class="flex-sm-fill">
                  <h2 class="mb-0">
                    <span class="text-secondary">{{ integration.name }}</span>
                  </h2>
                  <h5 class="mr-auto">
                    <b-icon icon="hash"/> {{ integration.id }}
                  </h5>
                </div>
                <div class="mt-3 mt-sm-0 ml-sm-3 flex-shrink-0">
                  <b-dropdown variant="outline-secondary" class="ml-1 pb-2" size="sm" right text="Actions" aria-label="Order actions toolbar">
                    <b-dropdown-item @click="run" class="mx-1" aria-label="Create ticket" >Run integration</b-dropdown-item>
                    <b-dropdown-item disabled class="mx-1" aria-label="Create ticket" >Pause integration</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item disabled class="mx-1" aria-label="Create ticket" >Cancel integration</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="test" class="mx-1" aria-label="Create ticket" >Connection test</b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Grid Wrapper / Layout -->
      <div class="details-wrapper container bg-site-light py-4 rounded">

        <!-- form part -->
        <div class="row auto-grid-wrapper">

          <!-- COL 1 : SHOP INFO + ORDER CRITERIA  -->
          <div class="col-md-6 xcol-sm-12">
            
            <b-card bg-variant="light" class="mb-4" v-if="!locked_integration">
              <template #header>
                <h6 class="mb-0">Shop Integration</h6>
              </template>

              <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">
                
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-checkbox
                    v-model="integration.active"
                    @change="changeActiveState"
                    name="active"
                    :aria-describedby="ariaDescribedby"
                    switch
                    size="lg"
                    :disabled="primary_integration"
                  >
                    Active
                  </b-form-checkbox>
                </b-form-group>

                <b-form-group label="Name:" label-for="name" description="Descriptive name of integration.">
                  <b-form-input
                    type="text"
                    v-model="integration.name"
                    name="name"
                    placeholder="Unnamed integration"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Class:" label-for="class" description="Instruction class of integration.">
                  <b-form-input
                    type="text"
                    v-model="integration.class"
                    name="class"
                    placeholder="Standard"
                    disabled
                  ></b-form-input>
                </b-form-group>

                <!-- SCHEDULE -->
                <div class="active-schedules">
                  <b-form-group label="Communication slots:" label-for="schedule">
                    <b-form-checkbox v-for="(item, item_index) in integration.schedule" :key="item_index" :value="item_index">
                      <span class="days"><b>{{ days_string(item.days) }}</b></span>: 
                      <span class="from">{{ item.from.toString().padStart(2,'0') + ":00" }}</span> - 
                      <span class="to">{{ item.to.toString().padStart(2,'0') + ":00" }}</span>
                    </b-form-checkbox>
                  </b-form-group>
                </div>
                <b-form-group label="New period:" label-for="schedule" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    v-model="new_schedule.days"
                    :aria-describedby="ariaDescribedby"
                    name="scheduled_days"
                  >
                    <b-form-checkbox value="1">Mon</b-form-checkbox>
                    <b-form-checkbox value="2">Tue</b-form-checkbox>
                    <b-form-checkbox value="3">Wed</b-form-checkbox>
                    <b-form-checkbox value="4">Thu</b-form-checkbox>
                    <b-form-checkbox value="5">Fri</b-form-checkbox>
                    <b-form-checkbox value="6">Sat</b-form-checkbox>
                    <b-form-checkbox value="7">Sun</b-form-checkbox>
                  </b-form-checkbox-group>

                  <b-input-group prepend="Scanning time" class="mt-2">
                    <b-form-select v-model="new_schedule.from" :options="hours"></b-form-select>
                    <b-form-select v-model="new_schedule.to" :options="hours"></b-form-select>
                  </b-input-group>
                </b-form-group>

                <div class="mt-4 text-right">
                  <b-button type="submit" variant="secondary" size="sm" class="mr-1">Submit</b-button>
                  <b-button type="reset" variant="secondary" size="sm">Reset</b-button>
                </div>
              </b-form>

            </b-card>

            <!-- Order Criteria -->
            <b-card bg-variant="light" v-if="integration_component">
              <template #header>
                <h6 class="mb-0">Integration Settings ({{ integration_component }})</h6>
              </template>
              <IntegrationSettings :componentName="integration_component" :id="integration.id"/>
            </b-card><!-- Order Criteria END -->

          </div><!-- COL 1 END  -->


          <!-- COL 2 : PRINTERAPI INFO -->
          <div class="xcol-md-6 xcol-sm-12">

            <b-card bg-variant="light">
              <template #header>
                <h6 class="mb-0">PrinterAPI Integration</h6>
              </template>

              <!-- COPY OF BELOW - JUST FOR TEST/LOOKS -->
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-checkbox
                  v-model="integration.settings.printerapi_mode"
                  name="printerapi_modeXXX"
                  :aria-describedby="ariaDescribedby"
                  switch
                  size="lg"
                  :disabled="locked_integration"
                >
                  Place orders automatically
                </b-form-checkbox>
              </b-form-group>
              <b-form-group label="New Order Prefix:" label-for="printerapi_order_prefix">
                <b-form-input
                  type="text"
                  v-model="integration.settings.printerapi_order_prefix"
                  name="printerapi_prefixXXX"
                  :readonly="locked_integration"
                ></b-form-input>
              </b-form-group>          
              <!-- END DUPLICATE CODE -->

              <div class="text-right">
                <b-button v-b-toggle.panel-printerapi-collapse-1 variant="default">
                  Details
                  <span class="when-open">
                    <b-icon icon="caret-up-fill" class="pt-1"></b-icon>
                  </span>
                  <span class="when-closed">
                    <b-icon icon="caret-down-fill" class="pt-1"></b-icon>
                  </span>
                </b-button>
              </div>
              <b-collapse id="panel-printerapi-collapse-1" class="mt-2">
                <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">

                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-checkbox
                      v-model="integration.settings.printerapi_mode"
                      name="printerapi_mode"
                      :aria-describedby="ariaDescribedby"
                      switch
                      size="lg"
                      :disabled="locked_integration"
                    >
                      Place orders automatically
                    </b-form-checkbox>
                  </b-form-group>

                  <!-- PRINTERAPI INPUTS -->
                  <b-form-group label="New Order Prefix:" label-for="printerapi_order_prefix">
                    <b-form-input
                      type="text"
                      v-model="integration.settings.printerapi_order_prefix"
                      name="printerapi_prefix"
                      :readonly="locked_integration"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Auth Key:" label-for="auth_printerapi_apikey">
                    <b-form-input
                      type="text"
                      v-model="integration.auth.printerapi.apikey"
                      name="auth_printerapi_apikey"
                      disabled
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Auth Secret:" label-for="auth_printerapi_secret">
                    <b-form-input
                      type="password"
                      v-model="integration.auth.printerapi.secret"
                      name="auth_printerapi_secret"
                      disabled
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Auth Server:" label-for="auth_printerapi_server">
                    <b-form-input
                      type="text"
                      v-model="integration.auth.printerapi.server"
                      name="auth_printerapi_server"
                      disabled
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Auth Path:" label-for="auth_printerapi_path">
                    <b-form-input
                      type="text"
                      v-model="integration.auth.printerapi.path"
                      name="auth_printerapi_path"
                      disabled
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Auth Name:" label-for="auth_printerapi_name">
                    <b-form-input
                      type="text"
                      v-model="integration.auth.printerapi.name"
                      name="auth_printerapi_name"
                      disabled
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Jobtype (from key):" label-for="printerapi_job_type">
                    <b-form-input
                      type="text"
                      v-model="integration.settings.printerapi_job_type"
                      name="printerapi_job_type"
                      readonly
                      placeholder="standard"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Account (from Key):" label-for="printerapi_account">
                    <b-form-input
                      type="text"
                      v-model="integration.settings.printerapi_account"
                      name="printerapi_account"
                      readonly
                    ></b-form-input>
                  </b-form-group>

                  <div class="text-right">
                    <b-button type="submit" variant="secondary" size="sm" class="mr-1">Submit</b-button>
                    <b-button type="reset" variant="secondary" size="sm">Reset</b-button>
                  </div>
                </b-form>
              </b-collapse>
            </b-card>
          </div><!-- PRINTERAPI END -->

            <!--
            <b-button type="submit" variant="primary" class="mr-1">Submit</b-button>
            <b-button type="reset" variant="danger">Reset</b-button>
            -->
        </div><!-- end row -->

      </div>

    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import IntegrationSettings from '@/components/IntegrationSettings.vue';

export default {
  components: {
    IntegrationSettings,
  },

  data() {
    return {
      loading: false,
      error: false,
      
      integration: null,

      new_schedule_time1: 0,
      new_schedule_time2: 24,
    }
  },

  computed: {
    ...mapGetters([
      'timestampAsDate',
      'integrationById'
    ]),

    integration_id() { 
      return this.$route.params.integration_id;
    },

    
    integration_component() {
      if ( ! this.integration.id )
        return false;
      return this.integration.class.split(/[\\ ]+/).pop(); // Get last part of backslash divided string
    },

    locked_integration() {
      return this.integration?.locked;
    },
    primary_integration() {
      return this.integration?.primary_integration;
    },

    /*
    integration() {
      return this.integrationById( this.integration_id );
    }
    */
    new_schedule() { // Placeholder for when we make multiple schedules... for now just a return of a possible first scheduled item.
      return this.integration?.schedule[0] ?? [];
    },

    hours() {
      let hours = [];
      for(let hour = 0; hour <= 24; hour++) {
        hours.push({ value: hour, text: hour.toString().padStart(2, '0') + ":00" });
      }
      return hours;
    },

  },

  created () {
    this.fetchData();
  },

  // mounted() {
  //   this.integration = Object.assign({}, JSON.parse(JSON.stringify(this.integrationById(this.integration_id))));
  // },

  methods: {
    ...mapActions('integration', [
      'getIntegrationPromise',
    ]),

    // FETCH WORKING OBJECT
    fetchData() {
      // Reset all
      this.error = this.integration = null;
      this.loading = true;

      this.getIntegrationPromise(this.integration_id)
      .then((result) => {
        if ( result.data.status === 'ok' ) {
          this.integration = Object.assign(result.data.integration);
        }
        if ( result.data.status !== 'ok' ) {
          this.error = result.data.message || 'Could not load data!';
          this.$toastIt(this.error, {variant: 'danger', autoHideDelay: 2000});
        }
        this.loading = false;        
      })
      .catch((error) => {
        this.$toastIt(error.message, {variant: 'danger', autoHideDelay: 2000});
        this.error = error.message;
        this.loading = false;
      });
    },


    onSubmit() {},
    onReset() {
      this.integration = Object.assign({}, JSON.parse(JSON.stringify(this.integrationById(this.integration_id))));
    },

    days_string( days ) {
      return days.map( day => {
        return ['Mon','Tue','Wed','Thu','Fri','Sat','Sun'][day-1] ?? 'Unknown value';
      }).join(', ');
    },
 
    changeActiveState() {
      if ( this.integration.active ) {
        this.$confirmIt(`Are you sure that you want to toggle active state off?`)
        .then(value => {
          if (value) {
            //this.$showIt("Changed, but not in real life!");
          } else {
            this.integration.active = true;
          }
        });
      }
    },

    // Run integration
    run() {
      console.log('run integration', this.integration_id );
      this.$http.get(`${this.$store.state.api_base_url}/integration/${this.integration_id}/run`)
      .then((result) => {
        console.log(result);
      });
    },

    // Test integration
    test() {
      console.log('run integration', this.integration_id );
      this.$http.get(`${this.$store.state.api_base_url}/integration/${this.integration_id}/test`)
      .then((result) => {
        if ( result.data?.status === "success")
          console.log(`Connection test successfull!`);
        else
          console.log(`Connection failed (${result.data.error})`);
      });
    }

  },

}
</script>


<style lang="scss">
</style>

<style lang="scss" scoped>
  .integration_details {
    text-align: left;
  }

  .auto-grid-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  // Collapse
  .collapsed > .when-open,
  .not-collapsed > .when-closed {
    display: none;
  }
</style>