<template>

  <div class="content-builder-wrapper">
    <h5>This is a manual component</h5>
    <p>You can't do shit here!</p>
  </div>

</template>


<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Manual_v0',

  props: [
    'id' // Assigned to work_item
  ],

  data() {
    return {
      settings : {},

      order_age_days: 0,
      order_age_hours: 0,

      available_statuses: ['processing', 'pending', 'completed'],
      selected_statuses: []
    }
  },

  mounted() {
    // Get integration data and it's settings. Get only keys of interest (?).
    this.onReset();
  },

  computed: {
    ...mapGetters([
      'integrationById'
    ]),

    availableStatuses() {
      return this.available_statuses.filter(opt => this.selected_statuses.indexOf(opt) === -1)
    }
  },

  methods: {
    onSubmit() {},
    onReset() {
      const my_integration_data = this.integrationById(this.id).settings ?? [];
      const my_settings = Object.assign( {}, ...['printerapi_account', 'test'].map(x => ({ [x]: my_integration_data[x] })) );
      
      this.settings = my_settings;

      // Custom data splits etc...
      let order_age = 21600; // seconds = 6 hours for test
      this.order_age_days = Math.floor(order_age / 86400);
      this.order_age_hours = Math.floor((order_age % 86400) / 3600);
    },
  },
}
</script>