<template>

  <div class="content-builder-wrapper">
    <h5>This is the Woo Component</h5>
    <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">

      <b-form-group label="Minimum Order Age:" label-for="minimum_order_age">
        
        <div class="row">
          <div class="col-md-6 col-xs-12">
            <b-input-group append="Days" class="">
              <b-form-input type="number" v-model="order_age_days" class="text-right"></b-form-input>
            </b-input-group>
          </div>
          <div class="col-md-6 col-xs-12">
            <b-input-group append="Hours" class="">
              <b-form-input type="number" v-model="order_age_hours" class="text-right"></b-form-input>
            </b-input-group>
          </div>
        </div>
      </b-form-group>

      <b-form-group label="Statuses:" label-for="selected-statuses">
        <!-- Prop `add-on-change` is needed to enable adding tags vie the `change` event -->
        <b-form-tags
          id="selected-statuses"
          v-model="selected_statuses"
          size="lg"
          class="mb-2"
          add-on-change
          no-outer-focus
          no-add-on-enter
        >
          <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
            <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
              <li v-for="tag in tags" :key="tag" class="list-inline-item">
                <b-form-tag
                  @remove="removeTag(tag)"
                  :title="tag"
                  :disabled="disabled"
                  variant="success"
                >{{ tag }}</b-form-tag>
              </li>
            </ul>
            <b-form-select
              v-bind="inputAttrs"
              v-on="inputHandlers"
              :disabled="disabled || availableStatuses(selected_statuses).length === 0"
              :options="availableStatuses(selected_statuses)"
            >
              <template #first>
                <!-- This is required to prevent bugs with Safari -->
                <option disabled value="">Choose a status...</option>
              </template>
            </b-form-select>
            
            <b-input-group aria-controls="my-custom-tags-list" class="mt-2">
              <b-form-input
                type="text"
                v-bind="inputAttrs"
                v-on="inputHandlers"
                placeholder="Custom status"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="addTag()" variant="secondary">Add</b-button>
              </b-input-group-append>
            </b-input-group>
          </template>
        </b-form-tags>
      </b-form-group>

      <b-form-group label="Exclude statuses (just for 2 lists test):" label-for="exclude-statuses">
        <!-- Prop `add-on-change` is needed to enable adding tags vie the `change` event -->
        <b-form-tags
          id="exclude-statuses"
          v-model="excluded_statuses"
          size="lg"
          class="mb-2"
          add-on-change
          no-outer-focus
          no-add-on-enter
        >
          <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
            <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
              <li v-for="tag in tags" :key="tag" class="list-inline-item">
                <b-form-tag
                  @remove="removeTag(tag)"
                  :title="tag"
                  :disabled="disabled"
                  variant="danger"
                >{{ tag }}</b-form-tag>
              </li>
            </ul>
            <b-form-select
              v-bind="inputAttrs"
              v-on="inputHandlers"
              :disabled="disabled || availableStatuses(excluded_statuses).length === 0"
              :options="availableStatuses(excluded_statuses)"
            >
              <template #first>
                <!-- This is required to prevent bugs with Safari -->
                <option disabled value="">Choose a status...</option>
              </template>
            </b-form-select>
            
            <b-input-group aria-controls="my-custom-tags-list" class="mt-2">
              <b-form-input
                type="text"
                v-bind="inputAttrs"
                v-on="inputHandlers"
                placeholder="Custom status"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="addTag()" variant="secondary">Add</b-button>
              </b-input-group-append>
            </b-input-group>
          </template>
        </b-form-tags>
      </b-form-group>

      <InputMetaKeys v-model="test"/>

      <div class="text-right">
        <b-button type="submit" variant="secondary" size="sm" class="mr-1">Submit</b-button>
        <b-button type="reset" variant="secondary" size="sm">Reset</b-button>
      </div>
    </b-form>
     {{ settings }}
  </div>

</template>


<script>
import { mapGetters } from 'vuex'
import InputMetaKeys from '@/components/InputMetaKeys.vue';

export default {
  name: 'Woocommerce_v0',

  components: {
    InputMetaKeys,
  },

  props: [
    'id' // Assigned to work_item
  ],

  data() {
    return {
      test: '[{"tag":"wedwed","meta":"wedwed"},{"tag":"wedwedded","meta":"wedwedwdasdasda"}]',
      settings : {},

      order_age_days: 0,
      order_age_hours: 0,

      available_statuses: ['processing', 'pending', 'completed'],
      selected_statuses: [],
      excluded_statuses: [],
    }
  },

  mounted() {
    // Get integration data and it's settings. Get only keys of interest (?).
    this.onReset();
  },

  computed: {
    ...mapGetters([
      'integrationById'
    ]),

  },

  methods: {
    // Available statuses left (difference between selected = provided, and available)
    availableStatuses( statuses ) {
      return this.available_statuses.filter(opt => statuses.indexOf(opt) === -1)
    },

    onSubmit() {},
    onReset() {
      const my_integration_data = this.integrationById(this.id).settings ?? [];
      const my_settings = Object.assign( {}, ...['printerapi_account', 'test'].map(x => ({ [x]: my_integration_data[x] })) );
      
      this.settings = my_settings;

      // Custom data splits etc...
      let order_age = 21600; // seconds = 6 hours for test
      this.order_age_days = Math.floor(order_age / 86400);
      this.order_age_hours = Math.floor((order_age % 86400) / 3600);
    },
  },
}
</script>