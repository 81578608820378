<template>
  <component :is="componentName" :id="id" />
</template>
<script>
import * as components from '@/components/integration'
export default {
  name: "IntegrationSettings",
  components: {
    ...components
  },
  props: {
    componentName: { type: String, required: true },
    id: { type: String, required: true }
  },
}
</script>