var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-builder-wrapper"},[_c('h5',[_vm._v("This is the Woo Component")]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)},"reset":function($event){$event.preventDefault();return _vm.onReset($event)}}},[_c('b-form-group',{attrs:{"label":"Minimum Order Age:","label-for":"minimum_order_age"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-xs-12"},[_c('b-input-group',{attrs:{"append":"Days"}},[_c('b-form-input',{staticClass:"text-right",attrs:{"type":"number"},model:{value:(_vm.order_age_days),callback:function ($$v) {_vm.order_age_days=$$v},expression:"order_age_days"}})],1)],1),_c('div',{staticClass:"col-md-6 col-xs-12"},[_c('b-input-group',{attrs:{"append":"Hours"}},[_c('b-form-input',{staticClass:"text-right",attrs:{"type":"number"},model:{value:(_vm.order_age_hours),callback:function ($$v) {_vm.order_age_hours=$$v},expression:"order_age_hours"}})],1)],1)])]),_c('b-form-group',{attrs:{"label":"Statuses:","label-for":"selected-statuses"}},[_c('b-form-tags',{staticClass:"mb-2",attrs:{"id":"selected-statuses","size":"lg","add-on-change":"","no-outer-focus":"","no-add-on-enter":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputAttrs = ref.inputAttrs;
var inputHandlers = ref.inputHandlers;
var disabled = ref.disabled;
var removeTag = ref.removeTag;
return [(tags.length > 0)?_c('ul',{staticClass:"list-inline d-inline-block mb-2"},_vm._l((tags),function(tag){return _c('li',{key:tag,staticClass:"list-inline-item"},[_c('b-form-tag',{attrs:{"title":tag,"disabled":disabled,"variant":"success"},on:{"remove":function($event){return removeTag(tag)}}},[_vm._v(_vm._s(tag))])],1)}),0):_vm._e(),_c('b-form-select',_vm._g(_vm._b({attrs:{"disabled":disabled || _vm.availableStatuses(_vm.selected_statuses).length === 0,"options":_vm.availableStatuses(_vm.selected_statuses)},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Choose a status...")])]},proxy:true}],null,true)},'b-form-select',inputAttrs,false),inputHandlers)),_c('b-input-group',{staticClass:"mt-2",attrs:{"aria-controls":"my-custom-tags-list"}},[_c('b-form-input',_vm._g(_vm._b({attrs:{"type":"text","placeholder":"Custom status"}},'b-form-input',inputAttrs,false),inputHandlers)),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.addTag()}}},[_vm._v("Add")])],1)],1)]}}]),model:{value:(_vm.selected_statuses),callback:function ($$v) {_vm.selected_statuses=$$v},expression:"selected_statuses"}})],1),_c('b-form-group',{attrs:{"label":"Exclude statuses (just for 2 lists test):","label-for":"exclude-statuses"}},[_c('b-form-tags',{staticClass:"mb-2",attrs:{"id":"exclude-statuses","size":"lg","add-on-change":"","no-outer-focus":"","no-add-on-enter":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputAttrs = ref.inputAttrs;
var inputHandlers = ref.inputHandlers;
var disabled = ref.disabled;
var removeTag = ref.removeTag;
return [(tags.length > 0)?_c('ul',{staticClass:"list-inline d-inline-block mb-2"},_vm._l((tags),function(tag){return _c('li',{key:tag,staticClass:"list-inline-item"},[_c('b-form-tag',{attrs:{"title":tag,"disabled":disabled,"variant":"danger"},on:{"remove":function($event){return removeTag(tag)}}},[_vm._v(_vm._s(tag))])],1)}),0):_vm._e(),_c('b-form-select',_vm._g(_vm._b({attrs:{"disabled":disabled || _vm.availableStatuses(_vm.excluded_statuses).length === 0,"options":_vm.availableStatuses(_vm.excluded_statuses)},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Choose a status...")])]},proxy:true}],null,true)},'b-form-select',inputAttrs,false),inputHandlers)),_c('b-input-group',{staticClass:"mt-2",attrs:{"aria-controls":"my-custom-tags-list"}},[_c('b-form-input',_vm._g(_vm._b({attrs:{"type":"text","placeholder":"Custom status"}},'b-form-input',inputAttrs,false),inputHandlers)),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.addTag()}}},[_vm._v("Add")])],1)],1)]}}]),model:{value:(_vm.excluded_statuses),callback:function ($$v) {_vm.excluded_statuses=$$v},expression:"excluded_statuses"}})],1),_c('InputMetaKeys',{model:{value:(_vm.test),callback:function ($$v) {_vm.test=$$v},expression:"test"}}),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"secondary","size":"sm"}},[_vm._v("Submit")]),_c('b-button',{attrs:{"type":"reset","variant":"secondary","size":"sm"}},[_vm._v("Reset")])],1)],1),_vm._v(" "+_vm._s(_vm.settings)+" ")],1)}
var staticRenderFns = []

export { render, staticRenderFns }