<template>
  <div class="meta-keys">

    <div class="meta-key" v-for="(key, key_index) in keys" :key="`metakey-${key_index}`">
      <b-input-group class="mb-1">
        <b-form-input v-model="key.tag" aria-label="Tag" disabled></b-form-input>
        <b-form-input v-model="key.meta" aria-label="Meta" disabled></b-form-input>
        <b-input-group-append>
          <b-button size="sm" text="Button" variant="danger" v-on:click="removeMeta(key_index)">Remove</b-button>
        </b-input-group-append>
      </b-input-group>
    </div>    

    <div class="new-meta-key mt-2">
      <b-input-group class="mb-3">
        <b-form-input v-model="new_tag" aria-label="Tag" placeholder="Tag"></b-form-input>
        <b-form-input v-model="new_meta" aria-label="Path" placeholder="Meta"></b-form-input>
        <b-input-group-append>
          <b-button size="sm" text="Button" variant="primary" class="px-4" v-on:click="addMeta()">Add</b-button>
        </b-input-group-append>
      </b-input-group>
    </div>    

  </div>
</template>

<script>
export default {
  name: 'InputMetaKeys',
  
  data() {
    return {
      keys: [],

      new_tag: '',
      new_meta: '',
    }
  },

  props: {
    id: {
      type: String
    },
    label: {
      type: String,
      required: false
    },
    value: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    // Get integration data and it's settings. Get only keys of interest (?).
    this.keys = JSON.parse(this.value);
  },

  methods: {
    updateValue: function (value) {
      this.$emit('input', value)
    },

    addMeta: function () {
      let new_item = {
        tag: this.new_tag,
        meta: this.new_meta,
      };

      this.new_tag = '';
      this.new_meta = '';

      this.keys.push(new_item);

      const value = JSON.stringify(this.keys);
      this.$emit('input', value)
    },

    removeMeta: function (index) {
      this.keys.splice(index, 1);

      const value = JSON.stringify(this.keys);
      this.$emit('input', value)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
